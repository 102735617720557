import React, { useCallback } from "react"

import { Link, navigate } from "decentraland-gatsby/dist/plugins/intl"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import unescape from "lodash/unescape"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { BlogPostAttributes } from "../../../modules/formats"
import LoadingText from "../../Loading/LoadingText"

import "./PostCard.css"

export default React.memo(function PostCard(props: {
  post?: BlogPostAttributes
  loading?: boolean
}) {
  const { post, loading } = props
  const image = useImageOptimization(props.post?.image)
  const handleOnClick = useCallback(
    (e) => {
      e.preventDefault()
      if (post) {
        navigate(post.url)
      }
    },
    [post]
  )
  return (
    <div className={TokenList.join(["post-card__card", loading && "loading"])}>
      <Link href={post?.url}>
        <div
          className="post-card__image"
          style={
            !loading && image
              ? {
                  backgroundImage: `url("${
                    (isWebpSupported() && image.webp) ||
                    image.jpg ||
                    image.optimized
                  }")`,
                }
              : {}
          }
        ></div>
      </Link>
      {loading && (
        <div className="post-card__info">
          <div className="post-card__loading-header">
            <LoadingText type="span" size="medium" />
            <LoadingText type="span" size="medium" />
          </div>
          <LoadingText type="h2" size="full" lines={2} />
        </div>
      )}
      {!loading && post && (
        <div className="post-card__info">
          <span className="post-card__date">{post.publishedDate}</span>

          <span className="post-card__category">
            <a href={post.categoryUrl}>{post.categoryTitle}</a>
          </span>
          <a href={post.url} onClick={handleOnClick}>
            <h2>{unescape(post.title)}</h2>
          </a>
        </div>
      )}
    </div>
  )
})
